import { LinkSuggestionList } from "../LinkSuggestion";

// Function to get the selected text from the editor
const getSelectedText = (editorState) => {
  const selectionState = editorState.getSelection();
  const contentState = editorState.getCurrentContent();

  if (selectionState.isCollapsed()) {
    // If no text is selected, return an empty string
    return "";
  }

  const startKey = selectionState.getStartKey();
  const endKey = selectionState.getEndKey();
  const startOffset = selectionState.getStartOffset();
  const endOffset = selectionState.getEndOffset();

  const blockMap = contentState.getBlockMap();
  let selectedText = "";

  let captureText = false;

  blockMap.forEach((block) => {
    const key = block.getKey();
    const blockText = block.getText();

    if (key === startKey && key === endKey) {
      // If start and end are in the same block
      selectedText = blockText.slice(startOffset, endOffset);
    } else if (key === startKey) {
      // Start capturing from startOffset
      selectedText += blockText.slice(startOffset) + "\n";
      captureText = true;
    } else if (key === endKey) {
      // Stop capturing after endOffset
      selectedText += blockText.slice(0, endOffset);
      captureText = false;
    } else if (captureText) {
      // Capture full block text between start and end block
      selectedText += blockText + "\n";
    }
  });

  return selectedText;
};

// Function to count words in a given text
const countWords = (text) => {
  const words = text.trim().split(/\s+/);
  return words && words.length === 1 && words[0] === "" ? 0 : words.length;
};

// Function to get the word count of the selected text
export const getSelectedWordCount = (editorState) => {
  const selectedText = getSelectedText(editorState);
  return countWords(selectedText);
};


export const getNewSuggestionList = (defaultWebsiteUrl) => {
  const basePath = defaultWebsiteUrl.endsWith("/")
    ? defaultWebsiteUrl
    : `${defaultWebsiteUrl}/`;

  const newLinkSuggestionList = {};

  for (const [key, value] of Object.entries(LinkSuggestionList)) {
    newLinkSuggestionList[key] = basePath + value;
  }
  return newLinkSuggestionList;
};