import React, { useState, useEffect } from "react";

const getSortedLinkCounts = (linkCounts) => {
  const price4limoLinks = [];
  const otherLinks = [];

  Object.entries(linkCounts).forEach(([url, count]) => {
    if (url.includes("price4limo.com")) {
      price4limoLinks.push([url, count]);
    } else {
      otherLinks.push([url, count]);
    }
  });

  return [
    ...otherLinks.sort((a, b) => b[1] - a[1]),
    ...price4limoLinks.sort((a, b) => b[1] - a[1]),
  ];
};

const LinkCountSidebar = ({ linkCounts, onLinkClick, domainUrl }) => {
  const defaultSortedLinkCounts = getSortedLinkCounts(linkCounts);
  const [search, setSearch] = useState("");
  const [filteredLinkCounts, setFilteredLinkCounts] = useState(
    defaultSortedLinkCounts
  );

  useEffect(() => {
    if (search) {
      const filteredLinks = defaultSortedLinkCounts.filter(([link]) =>
        link.toLowerCase().includes(search.toLowerCase())
      );
      setFilteredLinkCounts(filteredLinks);
    } else {
      const sortedLinkCounts = getSortedLinkCounts(linkCounts);
      setFilteredLinkCounts(sortedLinkCounts);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, linkCounts]);

  const handleLinkClick = (url) => {
    if (onLinkClick) {
      onLinkClick(url);
    }
  };

  return (
    <div
      style={{
        width: "320px",
        height: "100vh",
        position: "fixed",
        right: 0,
        top: 0,
        backgroundColor: "#f8f9fa",
        borderLeft: "1px solid #dee2e6",
        padding: "20px",
        overflowY: "auto",
        boxShadow: "-2px 0 5px rgba(0,0,0,0.1)",
      }}
    >
      <h3
        style={{
          marginBottom: "20px",
          color: "#343a40",
          borderBottom: "2px solid #343a40",
          paddingBottom: "10px",
        }}
      >
        Link Counter 🔗
      </h3>

      <input
        type="search"
        placeholder="Search..."
        style={{
          marginBottom: "10px",
          height: "31px",
          width: "100%",
        }}
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />

      {filteredLinkCounts?.length === 0 && search !== "" && (
        <p style={{ textAlign: "center" }}>No Result Found.</p>
      )}

      {filteredLinkCounts.map(([url, count]) => {
        const isDifferentUrl = !url.includes(domainUrl);
        const shouldHighlight = count > 1;

        let backgroundColor = "white";
        if (isDifferentUrl || shouldHighlight) {
          backgroundColor = "red";
        }

        const badgeColor =
          isDifferentUrl || shouldHighlight ? "darkred" : "blue";
        const textColor = isDifferentUrl || shouldHighlight ? "white" : "black";

        return (
          <div
            key={`${url}-${count}`}
            style={{
              backgroundColor: backgroundColor,
              border: "1px solid #ced4da",
              borderRadius: "4px",
              padding: "10px",
              marginBottom: "10px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              fontSize: "14px",
              cursor: "pointer",
            }}
            onClick={() => handleLinkClick(url)}
          >
            <span
              style={{
                maxWidth: "250px",
                wordBreak: "break-all",
                color: textColor,
              }}
            >
              {url}
            </span>
            <span
              style={{
                backgroundColor: badgeColor,
                color: "white",
                borderRadius: "50%",
                width: "24px",
                height: "24px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontWeight: "bold",
              }}
            >
              {count}
            </span>
          </div>
        );
      })}
    </div>
  );
};

export default LinkCountSidebar;
