import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Ideogram from "./Ideogram";
import ImageProcessor from "./ImageProcessor";
import RichTextEditor from "./Draft";
import Super68ImageGenerator from "./Super68ImageGenerator";
import "./App.css";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          {/* Home route */}
          <Route path="/" element={<RichTextEditor />} />

          {/* Image processor route */}
          <Route path="/magic" element={<ImageProcessor />} />

          <Route path="/blast" element={<Ideogram />} />

          <Route path="/super68" element={<Super68ImageGenerator />} />

          {/* Catch all non-existing routes and redirect to home */}
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
