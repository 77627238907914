import React, { useState } from "react";
import JSZip from "jszip";
import { Key } from "./Key";
import { Super68ImagePropts } from "./Super68ImagePropts";

const Super68ImageGenerator = () => {
  const [companyName, setCompanyName] = useState("");
  const [stateName, setStateName] = useState("");
  const [prompts, setPrompts] = useState([]);
  const [generatedImages, setGeneratedImages] = useState([]);
  const [loading, setLoading] = useState([]);
  const [error, setError] = useState(null);

  // API key (in a real app, this should be secured)
  const API_KEY = Key;

  const generateImage = async (prompt, index, imageName) => {
    try {
      setLoading((prev) => {
        const newLoading = [...prev];
        newLoading[index] = true;
        return newLoading;
      });
      setError(null);

      const response = await fetch("https://api.ideogram.ai/generate", {
        method: "POST",
        headers: {
          "Api-Key": API_KEY,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          image_request: {
            prompt,
            aspect_ratio:
              imageName === "56-passenger-motorcoach"
                ? "ASPECT_1_1"
                : "ASPECT_16_9",
            model: "V_2_TURBO",
            magic_prompt_option: "OFF",
            style_type: "GENERAL",
          },
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();

      // Access the URL from the correct path in the response
      if (data.data && data.data[0] && data.data[0].url) {
        setGeneratedImages((prev) => {
          const newImages = [...prev];
          newImages[index] = data.data[0].url; // Changed this line to access correct URL path
          return newImages;
        });
      } else {
        throw new Error("No image URL in response");
      }
    } catch (err) {
      setError(`Error generating image: ${err.message}`);
    } finally {
      setLoading((prev) => {
        const newLoading = [...prev];
        newLoading[index] = false;
        return newLoading;
      });
    }
  };

  const replacePlaceholders = (data, replacements) => {
    return data.map((item) => ({
      ...item,
      prompt: item.prompt
        .replace(/{Company}/g, replacements.company)
        .replace(/{State Name}/g, replacements.stateName),
    }));
  };

  const generateAllImages = async () => {
    if (stateName.trim() === "" || companyName.trim() === "") {
      setError("Please enter company name and state name first");
      return;
    } else {
      setError("");
    }

    const replacedImagesPrompts = replacePlaceholders(Super68ImagePropts, {
      company: companyName,
      stateName: stateName,
    });

    setPrompts(replacedImagesPrompts);

    // Initialize arrays for new batch
    setGeneratedImages(new Array(replacedImagesPrompts.length).fill(null));
    setLoading(new Array(replacedImagesPrompts.length).fill(false));

    // Generate images in batches of 5
    for (let i = 0; i < replacedImagesPrompts.length; i += 5) {
      const batchPrompts = replacedImagesPrompts.slice(i, i + 5);
      const batchPromises = batchPrompts.map((prompt, batchIndex) =>
        generateImage(prompt.prompt, i + batchIndex, prompt.imageName)
      );
      await Promise.all(batchPromises);
    }
  };

  const downloadImage = async (url, prompt, imageName) => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      const fileName = `${imageName}.jpeg`;

      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (err) {
      setError(`Error downloading image: ${err.message}`);
    }
  };

  const downloadAllAsZip = async () => {
    try {
      const zip = new JSZip();

      const downloads = generatedImages.map(async (url, index) => {
        if (!url) return null;
        const response = await fetch(url);
        const blob = await response.blob();
        const fileName = `${prompts[index].imageName}.jpeg`;
        zip.file(fileName, blob);
      });

      await Promise.all(downloads);

      const content = await zip.generateAsync({ type: "blob" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(content);
      link.download = "generated-images.zip";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (err) {
      setError(`Error creating zip file: ${err.message}`);
    }
  };

  return (
    <div className="container">
      <h1 className="title">Image Generator</h1>

      {error && <div className="error-message">{error}</div>}

      <div className="input-section">
        <div className="d-flex">
          <input
            type="text"
            placeholder="Enter company name"
            value={companyName}
            onChange={(e) => setCompanyName(e.target.value)}
            className="normal-input mr-2"
          />
          <input
            type="text"
            placeholder="Enter state name"
            value={stateName}
            onChange={(e) => setStateName(e.target.value)}
            className="normal-input ml-2"
          />
        </div>
        <div className="button-group justify-content-center">
          <button
            onClick={generateAllImages}
            disabled={loading.some(Boolean)} //prompts.length === 0 ||
            className="button"
          >
            Generate All Images
          </button>
        </div>
      </div>

      <div className="prompts-container">
        {prompts.map((prompt, index) => (
          <div key={index} className="prompt-card">
            <div className="prompt-text">
              <strong>Prompt {index + 1}:</strong> {prompt.imageName}
            </div>

            {generatedImages[index] && (
              <div className="image-container">
                <img
                  src={generatedImages[index]}
                  alt={prompt.imageName}
                  className="generated-image"
                />
                <div className="button-group">
                  <button
                    onClick={() =>
                      generateImage(prompt.prompt, index, prompt.imageName)
                    }
                    disabled={loading[index]}
                    className="button"
                  >
                    {loading[index] ? "Generating..." : "Regenerate"}
                  </button>
                  <button
                    onClick={() =>
                      downloadImage(
                        generatedImages[index],
                        prompt.prompt,
                        prompt.imageName
                      )
                    }
                    className="button button-outline"
                  >
                    Download
                  </button>
                </div>
              </div>
            )}

            {loading[index] && (
              <div className="loading-container">
                <div className="loading-spinner"></div>
                <p>Generating image...</p>
              </div>
            )}
          </div>
        ))}
      </div>

      {generatedImages.some(Boolean) && (
        <div className="actions-container">
          <button onClick={downloadAllAsZip} className="button button-outline">
            Download All
          </button>
        </div>
      )}
    </div>
  );
};

export default Super68ImageGenerator;
