export const Super68ImagePropts = [
  // charter-bus
  {
    prompt:
      "A photograph of a large white modern, 2024 motorcoach driving on a road in {Company}, {State Name}. The motorcoach has the text “Charter Bus Company {Company}” clearly visible on the side in black font. The photo is a RAW photo shot with a DSLR camera using an 85mm lens. A001_05231427_C001.braw",
    imageName: "charter-bus",
  },
  // 56-passenger-motorcoach
  {
    prompt:
      "A photograph of a large white modern, 2024 motorcoach driving on a road in {Company}, {State Name}. The motorcoach has the text “Charter Bus Company {Company}” clearly visible on the side in black font. The photo is a RAW photo shot with a DSLR camera using an 85mm lens. A001_05231427_C001.braw",
    imageName: "56-passenger-motorcoach",
  },
  // 15-passenger-minibus
  {
    prompt:
      "A photograph of  a large white modern Champion Ford F550 Challenger Shuttle Bus in a photographic studio with white walls and even. The bus is parked on a smooth surface. The background is clean and free of distractions. The image has a low ceiling and is an award-winning composition. The photo is a RAW photo shot with a DSLR camera using an 85mm lens. A001_05231427_C001.braw",
    imageName: "15-passenger-minibus",
  },
  {
    prompt:
      "Photo of black minibus seats. It features rows of plush seats with chevron-patterned backrests. 14 seats total in 2 x 2 rows, one flat aisle in the middle like a real minibus. The floor appears to be dark colored wood. The photo is a RAW photo shot with a DSLR camera using an 85mm lens.  A001_05231427_C001.braw",
    imageName: "15-passenger-minibus-rental",
  },
  {
    prompt:
      "Photo of grey, cloth/fabric minibus seats. It features rows of plush seats with chevron-patterned backrests. 14 seats total in 2 x 2 rows, one flat aisle in the middle like a real minibus. The floor appears to be dark colored wood.  The photo is a RAW photo shot with a DSLR camera using an 85mm lens. A001_05231427_C001.braw",
    imageName: "15-passenger-minibus-interior",
  },
  {
    prompt:
      "Photo of black minibus seats. It features rows of plush seats with chevron-patterned backrests. 14 seats total in 2 x 2 rows, one flat aisle in the middle like a real minibus. The floor appears to be dark colored wood. The photo is a RAW photo shot with a DSLR camera using an 85mm lens.  A001_05231427_C001.braw",
    imageName: "15-passenger-minibus-inside",
  },
  // 18-passenger-minibus
  {
    prompt:
      "A photograph of  a large white modern Champion Ford F550 Challenger Shuttle Bus in a photographic studio with white walls and even. The bus is parked on a smooth surface. The background is clean and free of distractions. The image has a low ceiling and is an award-winning composition. The photo is a RAW photo shot with a DSLR camera using an 85mm lens. A001_05231427_C001.braw",
    imageName: "18-passenger-minibus",
  },
  {
    prompt:
      "Photo of black minibus seats. It features rows of plush seats with chevron-patterned backrests. 14 seats total in 2 x 2 rows, one flat aisle in the middle like a real minibus. The floor appears to be dark colored wood. The photo is a RAW photo shot with a DSLR camera using an 85mm lens.  A001_05231427_C001.braw",
    imageName: "18-passenger-minibus-rental",
  },
  {
    prompt:
      "Photo of grey, cloth/fabric minibus seats. It features rows of plush seats with chevron-patterned backrests. 14 seats total in 2 x 2 rows, one flat aisle in the middle like a real minibus. The floor appears to be dark colored wood.  The photo is a RAW photo shot with a DSLR camera using an 85mm lens. A001_05231427_C001.braw",
    imageName: "18-passenger-minibus-interior",
  },
  {
    prompt:
      "Photo of black minibus seats. It features rows of plush seats with chevron-patterned backrests. 14 seats total in 2 x 2 rows, one flat aisle in the middle like a real minibus. The floor appears to be dark colored wood. The photo is a RAW photo shot with a DSLR camera using an 85mm lens.  A001_05231427_C001.braw",
    imageName: "18-passenger-minibus-inside",
  },
  // 20-passenger-minibus
  {
    prompt:
      "A photograph of  a large white modern Champion Ford F550 Challenger Shuttle Bus in a photographic studio with white walls and even. The bus is parked on a smooth surface. The background is clean and free of distractions. The image has a low ceiling and is an award-winning composition. The photo is a RAW photo shot with a DSLR camera using an 85mm lens. A001_05231427_C001.braw",
    imageName: "20-passenger-minibus",
  },
  {
    prompt:
      "Photo of black minibus seats. It features rows of plush seats with chevron-patterned backrests. 14 seats total in 2 x 2 rows, one flat aisle in the middle like a real minibus. The floor appears to be dark colored wood. The photo is a RAW photo shot with a DSLR camera using an 85mm lens.  A001_05231427_C001.braw",
    imageName: "20-passenger-minibus-rental",
  },
  {
    prompt:
      "Photo of grey, cloth/fabric minibus seats. It features rows of plush seats with chevron-patterned backrests. 14 seats total in 2 x 2 rows, one flat aisle in the middle like a real minibus. The floor appears to be dark colored wood.  The photo is a RAW photo shot with a DSLR camera using an 85mm lens. A001_05231427_C001.braw",
    imageName: "20-passenger-minibus-interior",
  },
  {
    prompt:
      "Photo of black minibus seats. It features rows of plush seats with chevron-patterned backrests. 14 seats total in 2 x 2 rows, one flat aisle in the middle like a real minibus. The floor appears to be dark colored wood. The photo is a RAW photo shot with a DSLR camera using an 85mm lens.  A001_05231427_C001.braw",
    imageName: "20-passenger-minibus-inside",
  },
  // 25-passenger-minibus
  {
    prompt:
      "A photograph of  a large white modern Champion Ford F550 Challenger Shuttle Bus in a photographic studio with white walls and even. The bus is parked on a smooth surface. The background is clean and free of distractions. The image has a low ceiling and is an award-winning composition. The photo is a RAW photo shot with a DSLR camera using an 85mm lens. A001_05231427_C001.braw",
    imageName: "25-passenger-minibus",
  },
  {
    prompt:
      "Photo of black minibus seats. It features rows of plush seats with chevron-patterned backrests. 14 seats total in 2 x 2 rows, one flat aisle in the middle like a real minibus. The floor appears to be dark colored wood. The photo is a RAW photo shot with a DSLR camera using an 85mm lens.  A001_05231427_C001.braw",
    imageName: "25-passenger-minibus-rental",
  },
  {
    prompt:
      "Photo of grey, cloth/fabric minibus seats. It features rows of plush seats with chevron-patterned backrests. 14 seats total in 2 x 2 rows, one flat aisle in the middle like a real minibus. The floor appears to be dark colored wood.  The photo is a RAW photo shot with a DSLR camera using an 85mm lens. A001_05231427_C001.braw",
    imageName: "25-passenger-minibus-interior",
  },
  {
    prompt:
      "Photo of black minibus seats. It features rows of plush seats with chevron-patterned backrests. 14 seats total in 2 x 2 rows, one flat aisle in the middle like a real minibus. The floor appears to be dark colored wood. The photo is a RAW photo shot with a DSLR camera using an 85mm lens.  A001_05231427_C001.braw",
    imageName: "25-passenger-minibus-inside",
  },
  // 28-passenger-minibus
  {
    prompt:
      "A photograph of  a white modern GM40 Freightliner S2 shuttle bus in a photographic studio with white walls and even. The bus has a modern design with a sleek curved front and a prominent grille. It has a high roof, large windows, and a sturdy build.  The bus is parked on a smooth surface. The background is clean and free of distractions. The image has a low ceiling and is an award-winning composition. The photo is a RAW photo shot with a DSLR camera using an 85mm lens. A001_05231427_C001.braw",
    imageName: "28-passenger-minibus",
  },
  {
    prompt:
      "Photo of black minibus seats. It features rows of plush seats with chevron-patterned backrests. 14 seats total in 2 x 2 rows, one flat aisle in the middle like a real minibus. The floor appears to be dark colored wood. The photo is a RAW photo shot with a DSLR camera using an 85mm lens.  A001_05231427_C001.braw",
    imageName: "28-passenger-minibus-rental",
  },
  {
    prompt:
      "Photo of grey, cloth/fabric minibus seats. It features rows of plush seats with chevron-patterned backrests. 14 seats total in 2 x 2 rows, one flat aisle in the middle like a real minibus. The floor appears to be dark colored wood.  The photo is a RAW photo shot with a DSLR camera using an 85mm lens. A001_05231427_C001.braw",
    imageName: "28-passenger-minibus-interior",
  },
  {
    prompt:
      "Photo of grey, cloth/fabric minibus seats. It features rows of plush seats with chevron-patterned backrests. 14 seats total in 2 x 2 rows, one flat aisle in the middle like a real minibus. The floor appears to be dark colored wood.  The photo is a RAW photo shot with a DSLR camera using an 85mm lens. A001_05231427_C001.braw",
    imageName: "28-passenger-minibus-inside",
  },
  // 30-passenger-minibus
  {
    prompt:
      "A photograph of  a white modern GM40 Freightliner S2 shuttle bus in a photographic studio with white walls and even. The bus has a modern design with a sleek curved front and a prominent grille. It has a high roof, large windows, and a sturdy build.  The bus is parked on a smooth surface. The background is clean and free of distractions. The image has a low ceiling and is an award-winning composition. The photo is a RAW photo shot with a DSLR camera using an 85mm lens. A001_05231427_C001.braw",
    imageName: "30-passenger-minibus",
  },
  {
    prompt:
      "Photo of black minibus seats. It features rows of plush seats with chevron-patterned backrests. 14 seats total in 2 x 2 rows, one flat aisle in the middle like a real minibus. The floor appears to be dark colored wood. The photo is a RAW photo shot with a DSLR camera using an 85mm lens.  A001_05231427_C001.braw",
    imageName: "30-passenger-minibus-rental",
  },
  {
    prompt:
      "Photo of grey, cloth/fabric minibus seats. It features rows of plush seats with chevron-patterned backrests. 14 seats total in 2 x 2 rows, one flat aisle in the middle like a real minibus. The floor appears to be dark colored wood.  The photo is a RAW photo shot with a DSLR camera using an 85mm lens. A001_05231427_C001.braw",
    imageName: "30-passenger-minibus-interior",
  },
  {
    prompt:
      "Photo of grey, cloth/fabric minibus seats. It features rows of plush seats with chevron-patterned backrests. 14 seats total in 2 x 2 rows, one flat aisle in the middle like a real minibus. The floor appears to be dark colored wood.  The photo is a RAW photo shot with a DSLR camera using an 85mm lens. A001_05231427_C001.braw",
    imageName: "30-passenger-minibus-inside",
  },
  // 35-passenger-minibus
  {
    prompt:
      "A photograph of  a white modern GM40 Freightliner S2 shuttle bus in a photographic studio with white walls and even. The bus has a modern design with a sleek curved front and a prominent grille. It has a high roof, large windows, and a sturdy build.  The bus is parked on a smooth surface. The background is clean and free of distractions. The image has a low ceiling and is an award-winning composition. The photo is a RAW photo shot with a DSLR camera using an 85mm lens. A001_05231427_C001.braw",
    imageName: "35-passenger-minibus",
  },
  {
    prompt:
      "Photo of black minibus seats. It features rows of plush seats with chevron-patterned backrests. 14 seats total in 2 x 2 rows, one flat aisle in the middle like a real minibus. The floor appears to be dark colored wood. The photo is a RAW photo shot with a DSLR camera using an 85mm lens.  A001_05231427_C001.braw",
    imageName: "35-passenger-minibus-rental",
  },
  {
    prompt:
      "Photo of grey, cloth/fabric minibus seats. It features rows of plush seats with chevron-patterned backrests. 14 seats total in 2 x 2 rows, one flat aisle in the middle like a real minibus. The floor appears to be dark colored wood.  The photo is a RAW photo shot with a DSLR camera using an 85mm lens. A001_05231427_C001.braw",
    imageName: "35-passenger-minibus-interior",
  },
  {
    prompt:
      "Photo of grey, cloth/fabric minibus seats. It features rows of plush seats with chevron-patterned backrests. 14 seats total in 2 x 2 rows, one flat aisle in the middle like a real minibus. The floor appears to be dark colored wood.  The photo is a RAW photo shot with a DSLR camera using an 85mm lens. A001_05231427_C001.braw",
    imageName: "35-passenger-minibus-inside",
  },
  // 50-passenger-charter-bus
  {
    prompt:
      "A photograph of a large white modern, 2024 motorcoach in a photographic studio with white walls and even lighting. The motorcoach is parked on a smooth surface. The background is clean and free of distractions. The image has a low ceiling and is an award-winning composition. The photo is a RAW photo shot with a DSLR camera using an 85mm lens. A001_05231427_C001.braw",
    imageName: "50-passenger-charter-bus",
  },
  {
    prompt:
      "Photo of black motorcoach seats. It features rows of plush seats with chevron-patterned backrests. 40 seats total in 2 x 2 rows, one flat aisle in the middle like a real motorcoach. The floor appears to be dark colored wood.  The photo is a RAW photo shot with a DSLR camera using an 85mm lens. A001_05231427_C001.braw",
    imageName: "50-passenger-charter-bus-rental",
  },
  {
    prompt:
      "Photo of grey motorcoach seats. It features rows of plush seats with chevron-patterned backrests. 40 seats total in 2 x 2 rows, one flat aisle in the middle like a real motorcoach. The floor appears to be dark colored wood. The photo is a RAW photo shot with a DSLR camera using an 85mm lens.  A001_05231427_C001.braw",
    imageName: "50-passenger-charter-bus-interior",
  },
  {
    prompt:
      "Photo of black motorcoach seats. It features rows of plush seats with chevron-patterned backrests. 40 seats total in 2 x 2 rows, one flat aisle in the middle like a real motorcoach. The floor appears to be dark colored wood.  The photo is a RAW photo shot with a DSLR camera using an 85mm lens. A001_05231427_C001.braw",
    imageName: "50-passenger-charter-bus-inside",
  },
  // 54-passenger-charter-bus
  {
    prompt:
      "A photograph of a large white modern, 2024 motorcoach in a photographic studio with white walls and even lighting. The motorcoach is parked on a smooth surface. The background is clean and free of distractions. The image has a low ceiling and is an award-winning composition. The photo is a RAW photo shot with a DSLR camera using an 85mm lens. A001_05231427_C001.braw",
    imageName: "54-passenger-charter-bus",
  },
  {
    prompt:
      "Photo of black motorcoach seats. It features rows of plush seats with chevron-patterned backrests. 40 seats total in 2 x 2 rows, one flat aisle in the middle like a real motorcoach. The floor appears to be dark colored wood.  The photo is a RAW photo shot with a DSLR camera using an 85mm lens. A001_05231427_C001.braw",
    imageName: "54-passenger-charter-bus-rental",
  },
  {
    prompt:
      "Photo of grey motorcoach seats. It features rows of plush seats with chevron-patterned backrests. 40 seats total in 2 x 2 rows, one flat aisle in the middle like a real motorcoach. The floor appears to be dark colored wood. The photo is a RAW photo shot with a DSLR camera using an 85mm lens.  A001_05231427_C001.braw",
    imageName: "54-passenger-charter-bus-interior",
  },
  {
    prompt:
      "Photo of black motorcoach seats. It features rows of plush seats with chevron-patterned backrests. 40 seats total in 2 x 2 rows, one flat aisle in the middle like a real motorcoach. The floor appears to be dark colored wood.  The photo is a RAW photo shot with a DSLR camera using an 85mm lens. A001_05231427_C001.braw",
    imageName: "54-passenger-charter-bus-inside",
  },
  // 55-passenger-charter-bus
  {
    prompt:
      "A photograph of a large white modern, 2024 motorcoach in a photographic studio with white walls and even lighting. The motorcoach is parked on a smooth surface. The background is clean and free of distractions. The image has a low ceiling and is an award-winning composition. The photo is a RAW photo shot with a DSLR camera using an 85mm lens. A001_05231427_C001.braw",
    imageName: "55-passenger-charter-bus",
  },
  {
    prompt:
      "Photo of black motorcoach seats. It features rows of plush seats with chevron-patterned backrests. 40 seats total in 2 x 2 rows, one flat aisle in the middle like a real motorcoach. The floor appears to be dark colored wood.  The photo is a RAW photo shot with a DSLR camera using an 85mm lens. A001_05231427_C001.braw",
    imageName: "55-passenger-charter-bus-rental",
  },
  {
    prompt:
      "Photo of grey motorcoach seats. It features rows of plush seats with chevron-patterned backrests. 40 seats total in 2 x 2 rows, one flat aisle in the middle like a real motorcoach. The floor appears to be dark colored wood. The photo is a RAW photo shot with a DSLR camera using an 85mm lens.  A001_05231427_C001.braw",
    imageName: "55-passenger-charter-bus-interior",
  },
  {
    prompt:
      "Photo of black motorcoach seats. It features rows of plush seats with chevron-patterned backrests. 40 seats total in 2 x 2 rows, one flat aisle in the middle like a real motorcoach. The floor appears to be dark colored wood.  The photo is a RAW photo shot with a DSLR camera using an 85mm lens. A001_05231427_C001.braw",
    imageName: "55-passenger-charter-bus-inside",
  },
  // 56-passenger-charter-bus
  {
    prompt:
      "A photograph of a large white modern, 2024 motorcoach in a photographic studio with white walls and even lighting. The motorcoach is parked on a smooth surface. The background is clean and free of distractions. The image has a low ceiling and is an award-winning composition. The photo is a RAW photo shot with a DSLR camera using an 85mm lens. A001_05231427_C001.braw",
    imageName: "56-passenger-charter-bus",
  },
  {
    prompt:
      "Photo of black motorcoach seats. It features rows of plush seats with chevron-patterned backrests. 40 seats total in 2 x 2 rows, one flat aisle in the middle like a real motorcoach. The floor appears to be dark colored wood.  The photo is a RAW photo shot with a DSLR camera using an 85mm lens. A001_05231427_C001.braw",
    imageName: "56-passenger-charter-bus-rental",
  },
  {
    prompt:
      "Photo of grey motorcoach seats. It features rows of plush seats with chevron-patterned backrests. 40 seats total in 2 x 2 rows, one flat aisle in the middle like a real motorcoach. The floor appears to be dark colored wood. The photo is a RAW photo shot with a DSLR camera using an 85mm lens.  A001_05231427_C001.braw",
    imageName: "56-passenger-charter-bus-interior",
  },
  {
    prompt:
      "Photo of black motorcoach seats. It features rows of plush seats with chevron-patterned backrests. 40 seats total in 2 x 2 rows, one flat aisle in the middle like a real motorcoach. The floor appears to be dark colored wood.  The photo is a RAW photo shot with a DSLR camera using an 85mm lens. A001_05231427_C001.braw",
    imageName: "56-passenger-charter-bus-inside",
  },
  // sprinter-van-with-driver
  {
    prompt:
      "photo of a modern black Mercedes Sprinter Van in a photographic studio with white walls and even lighting. The van is parked on a smooth surface. The background is clean and free of distractions.  Low ceiling... The photo is a RAW photo shot with a DSLR camera using an 85mm lens A001_05231427_C001.braw",
    imageName: "sprinter-van-with-driver",
  },
  {
    prompt:
      "Photo of black Mercedes sprinter van seats. It features 10 seats, rows of plush seats in 2 x 2 rows, one flat aisle in the middle like a real sprinter van. The floor appears to be dark colored wood.  The photo is a RAW photo shot with a DSLR camera using an 85mm lens. A001_05231427_C001.braw",
    imageName: "sprinter-van-with-driver-rental",
  },
  {
    prompt:
      "Photo of black Mercedes sprinter van seats. It features 10 seats, rows of plush seats in 2 x 2 rows, one flat aisle in the middle like a real sprinter van. The floor appears to be dark colored wood.  The photo is a RAW photo shot with a DSLR camera using an 85mm lens. A001_05231427_C001.braw",
    imageName: "sprinter-van-with-driver-interior",
  },
  {
    prompt:
      "Photo of black Mercedes sprinter van seats. There are multiple plush, black cloth seats arranged in two rows, each equipped with a white headrest and a safety belt. The floor appears to be dark colored wood. The photo is a RAW photo shot with a DSLR camera using an 85mm lens.  A001_05231427_C001.braw",
    imageName: "sprinter-van-with-driver-inside",
  },
  // school-bus-rental
  {
    prompt:
      "photo of a modern yellow school bus in a photographic studio with white walls and even lighting. The bus is parked on a smooth surface. The background is clean and free of distractions. Low ceiling... The photo is a RAW photo shot with a DSLR camera using an 85mm lens A001_05231427_C001.braw",
    imageName: "school-bus-rental",
  },
  {
    prompt:
      "Photo of school bus. It features rows of plush seats in 2 x 2 rows, one flat aisle in the middle like a real school bus. The floor appears to be dark colored wood. The photo is a RAW photo shot with a DSLR camera using an 85mm lens.  A001_05231427_C001.braw",
    imageName: "school-bus-rental-rental",
  },
  {
    prompt:
      "Photo of school bus. It features rows of plush seats in 2 x 2 rows, one flat aisle in the middle like a real school bus. The floor appears to be dark colored wood. The photo is a RAW photo shot with a DSLR camera using an 85mm lens.  A001_05231427_C001.braw",
    imageName: "school-bus-rental-interior",
  },
  {
    prompt:
      "Photo of school bus. It features rows of plush seats in 2 x 2 rows, one flat aisle in the middle like a real school bus. The floor appears to be dark colored wood. The photo is a RAW photo shot with a DSLR camera using an 85mm lens.  A001_05231427_C001.braw",
    imageName: "school-bus-rental-inside",
  },
  // party-bus-rental
  {
    prompt:
      "A photograph of  a large white modern Champion Ford F550 Challenger Shuttle Bus in a photographic studio with white walls and even. The bus is parked on a smooth surface. The background is clean and free of distractions. The image has a low ceiling and is an award-winning composition. The photo is a RAW photo shot with a DSLR camera using an 85mm lens. A001_05231427_C001.braw",
    imageName: "party-bus-rental",
  },
  // sprinter-limo-rental
  {
    prompt:
      "photo of a modern black Mercedes Sprinter Van in a photographic studio with white walls and even lighting. The van is parked on a smooth surface side ways. The background is clean and free of distractions.  Low ceiling... The photo is a RAW photo shot with a DSLR camera using an 85mm lens A001_05231427_C001.braw",
    imageName: "sprinter-limo-rental",
  },
  // corporate-bus-rental
  {
    prompt:
      "a happy corporate guy smiling at the camera while sitting in a modern motorcoach seat doing thumbs up. The photo is a RAW photo shot with a DSLR camera using an 85mm lens. A001_05231427_C001.braw",
    imageName: "corporate-bus-rental",
  },
  // wedding-transportation
  {
    prompt:
      "wedding couple smiling at camera. The photo is a RAW photo shot with a DSLR camera using an 85mm lens. A001_05231427_C001.braw",
    imageName: "wedding-transportation",
  },
  // sporting-event-transportation
  {
    prompt:
      "A photograph of a large white modern, 2024 motorcoach parked in an empty parking lot in {Company}.. The photo is a RAW photo shot with a DSLR camera using an 85mm lens. A001_05231427_C001.braw",
    imageName: "sporting-event-transportation",
  },
  // school-event-transportation
  {
    prompt:
      "A photograph of a large white modern, 2024 motorcoach in {Company,  State}.. The photo is a RAW photo shot with a DSLR camera using an 85mm lens. A001_05231427_C001.braw",
    imageName: "school-event-transportation",
  },
  // airport-shuttles
  {
    prompt:
      "A photograph of a large white modern, 2024 motorcoach driving to a {State} airport.. The photo is a RAW photo shot with a DSLR camera using an 85mm lens. A001_05231427_C001.braw",
    imageName: "airport-shuttles",
  },
  // private-event-transportation
  {
    prompt:
      "A photograph of a large white modern, 2024 motorcoach driving around {Company, State}.. The photo is a RAW photo shot with a DSLR camera using an 85mm lens. A001_05231427_C001.braw",
    imageName: "private-event-transportation",
  },
];
